<template>
  <div style="font-size: 0.9rem;">
    <van-cell-group>
      <div class="top">
        <van-cell :title="$t('收件地址')" v-if="type !== 'wooden'" :to="{
          name: 'contactList',
          query: {
            scene: 'pick',
          },
        }" is-link>
          <div v-if="contact">
            <div class="address">
              {{ contact.address }}
            </div>
            <span>{{ contact.name }} / {{ contact.mobile }}</span>
          </div>
          <div v-else>{{ $t("请选择收货地址") }}</div>
        </van-cell>

        <div style="display: flex; align-items: center; padding: 0px 15px" class="van-hairline--bottom">
          <div style="width: 100px">{{ $t("货物类别") }}</div>
          <van-radio-group v-model="cargoType" direction="horizontal" style="padding: 15px">
            <van-radio name="A" checked-color="#40c057">{{
              $t("A类")
            }}</van-radio>
            <van-radio name="B" checked-color="#40c057">{{
              $t("B类")
            }}</van-radio>
            <van-radio name="C" checked-color="#40c057">{{
              $t("C类")
            }}</van-radio>
          </van-radio-group>
        </div>

        <div style="display: flex; align-items: center; padding: 0px 15px" class="van-hairline--bottom">
          <div style="width: 100px">{{ $t("运输方式") }}</div>
          <van-radio-group v-model="expressWay" direction="horizontal" style="padding: 15px">
            <van-radio name="1" checked-color="#40c057">{{
              $t("陆运")
            }}</van-radio>
            <van-radio name="2" checked-color="#40c057">{{
              $t("海运")
            }}</van-radio>
          </van-radio-group>
        </div>

        <div style="display: flex; align-items: center; padding: 0px 15px" class="van-hairline--bottom">
          <div style="width: 100px">{{ $t("增值服务") }}</div>
          <van-checkbox v-model="woodenframe" checked-color="#40c057" style="padding: 15px">{{ $t("打木架") }}</van-checkbox>
        </div>

        <!-- <div style="display: flex; align-items: center; padding: 0px 15px" class="van-hairline--bottom">
          <div style="width: 100px">{{ $t("重量") + "(KG)" }}</div>
          <van-field size="large" :placeholder="$t('请输入重量')" v-model="weight" maxlength="8" type="number" />
        </div> -->
        <div style="display: flex" class="van-hairline--bottom">
          <div>
            <van-field v-model="length" :placeholder="$t('长') + '(cm)'" maxlength="8" type="digit" />
          </div>
          <div>
            <van-field :placeholder="$t('宽') + '(cm)'" type="digit" maxlength="8" v-model="width" />
          </div>
          <div>
            <van-field :placeholder="$t('高') + '(cm)'" v-model="height" maxlength="8" type="digit" />
          </div>
        </div>
      </div>
      <div style="padding: 10px; display: flex">
        <van-button block round style="flex: 1" @click="toPriceLabel">{{
          $t("查看价格表")
        }}</van-button>
        <van-button block round type="primary" :loading="submitLoading" :disabled="submitLoading"
          style="flex: 1; margin-left: 15px" @click="calculate">{{ $t("计算") }}</van-button>
      </div>
      <div v-if="result.fee" class="result">
        <div style="font-size: 18px; padding: 15px">{{ $t("预估费用") }}：</div>
        <van-cell :title="$t('计费方式')" :value="$t(convertBillingMethod(result.billing_method))" />
        <van-cell v-if="result && result.value" :title="$t('体积')" :value="result.value + 'm³'" />
        <van-cell :title="$t('转运费')" :value="result.fee + ' ' + $t('铢')" />
        <van-cell v-if="localResult && localResult.fee" :title="$t('本地运费')" :value="localResult.fee + ' ' + $t('铢')" />
        <van-cell v-if="woodenframeResult" :title="$t('打木架费')"
          :value="(woodenframeResult ? (woodenframeResult.fee + ' ' + $t('铢')) : '')" />
      </div>
    </van-cell-group>
    <div style="padding:10px;color: red;">{{ $t('最低消费0.2m³，少于0.2m³按照0.2m³计费。',{ minimumFee: minimumFee}) }}</div>

  </div>
</template>

<script>
import {
  Cell,
  CellGroup,
  Toast,
  Field,
  RadioGroup,
  Radio,
  Button,
  Checkbox,
} from "vant";
import {
  feeEstimation,
  localRouteFeeEstimation,
  woodenframeEstimation,
  calMinimumFee,
  getDefaultContact,
} from "@/service/api";
import { EventBus } from "@/utils/event-bus.js";
import { convertBillingMethod } from "@/utils/dict";

export default {
  components: {
    [Cell.name]: Cell,
    [CellGroup.name]: CellGroup,
    [Toast.name]: Toast,
    [Field.name]: Field,
    [RadioGroup.name]: RadioGroup,
    [Radio.name]: Radio,
    [Button.name]: Button,
    [Checkbox.name]: Checkbox,
  },
  data() {
    return {
      length: "",
      width: "",
      height: "",
      weight: 0,
      type: "inter",
      cargoType: "A",
      contact: null,
      woodenframe: false,
      expressWay: "1",
      fee: "",
      result: {},
      woodenframeResult: null,
      localResult: {},
      submitLoading: false,
      minimumFee: ""
    };
  },
  mounted() {
    EventBus.$on("contact-select", (msg) => {
      const contact = JSON.parse(msg);
      this.contact = contact;
    });
    this.getDefaultContact();
  },
  methods: {
    typeChange() {
      this.fee = null;
    },
    convertBillingMethod(value) {
      return convertBillingMethod(value);
    },
    toPriceLabel() {
      const locale = localStorage.getItem("locale") || "th-TH";
      let serviceName = "cargo_category-en.html";
      switch (locale) {
        case "zh-TW":
          serviceName = "cargo_category-cn.html";
          break;
        case "th-TH":
          serviceName = "cargo_category-th.html";
          break;
        default:
          serviceName = "cargo_category-en.html";
          break;
      }

      this.serviceUrl = "/category/" + serviceName;

      window.open(this.serviceUrl, "_blank");
    },
    doReset() {
      this.length = null;
      this.width = null;
      this.height = null;
      this.weight = null;
      this.cargoType = "A";
      this.woodenframe = false;
      this.result = {};
    },
    async getDefaultContact() {
      Toast.loading({
        duration: 0,
        forbidClick: true,
        message: this.$t("加载中..."),
      });

      const res = await getDefaultContact();
      if (res.success) {
        this.contact = res.data;
      } else Toast.fail(res.message);

      Toast.clear();
    },
    calculate() {
      if (!this.contact) {
        Toast.fail(this.$t("请选择收货地址"));
        return;
      }

      // if (!this.weight) {
      //   Toast.fail(this.$t("请输入重量"));
      //   return;
      // }

      if (!this.length) {
        Toast.fail(this.$t("请输入长度"));
        return;
      }

      if (!this.width) {
        Toast.fail(this.$t("请输入宽度"));
        return;
      }

      if (!this.height) {
        Toast.fail(this.$t("请输入高度"));
        return;
      }

      let payload = {
        ...payload,
        weight: this.weight,
        geoCode: this.contact.geoCode
      };

      if (this.length) {
        payload = { ...payload, length: this.length };
      }

      if (this.width) {
        payload = { ...payload, width: this.width };
      }

      if (this.height) {
        payload = { ...payload, height: this.height };
      }

      payload = {
        ...payload,
        expressWay: this.expressWay,
        cargoType: this.cargoType
      };
      this.feeEstimation(payload);

      this.localRouteFeeEstimation(payload);

      if (this.woodenframe) this.woodenFrameEstimation(payload);
    },

    async woodenFrameEstimation(payload) {
      this.submitLoading = true;
      const res = await woodenframeEstimation(payload);

      if (res.success) {
        // this.fee = res.data.fee;
        this.woodenframeResult = res.data;
      } else Toast.fail(res.message);

      this.submitLoading = false;
    },

    async feeEstimation(payload) {
      this.submitLoading = true;
      const res = await feeEstimation(payload);

      if (res.success) {
        // this.fee = res.data.fee;
        this.result = res.data;
      } else { 
        Toast.fail(res.message) 
      }

      //计算最低费用
      console.log("payload=",payload)
      const minimumRes = await calMinimumFee(payload);
      console.log('minimumRes=',minimumRes)
      if(minimumRes.success) {
        this.minimumFee = minimumRes.data.minimumFee
      } else {
        Toast.fail(minimumRes.message) 
      }

      this.submitLoading = false;
    },

    async localRouteFeeEstimation(payload) {
      this.submitLoading = true;
      const res = await localRouteFeeEstimation(payload);
      if (res.success) {
        // this.fee = res.data.fee;
        this.localResult = res.data;
      } else Toast.fail(res.message);

      this.submitLoading = false;
    },
  },
};
</script>
<style lang="less" scoped>
.contactor__name-wrapper {
  display: flex;
  align-items: center;

  div {
    flex: 1;
  }
}

::v-deep .van-field__label {
  width: auto;
  margin-right: 31px;
}

.top {
  .van-cell__value {
    min-width: 75%;
  }
}

.van-cell {
  font-size: 0.9rem;
  align-items: center;
}

.van-radio {
  padding: 5px 0px;
}
</style>